.App {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.icono{
  width: 38%;
}

.gris{
  background-color: #e0e0e0 !important;
}

.volver{
  margin-left: 15px;
  padding: 15px;
  border-radius: 15px;
  background-color: #e0e0e0 !important;
}

.imagenPortada{
  height: 510px;
  width: 100%;
}

.logoMenuResponsive{
  width: 100%;
}

/* Estilos para el menú */
.menuMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0; /* Color de fondo */
  padding: 10px;
  border-radius: 30px; /* Borde redondeado */
}

/* Estilos para los botones */
.botonMobile {
  text-decoration: none;
  color: #fff; /* Color del texto */
  background-color: #007bff; /* Color de fondo del botón */
  padding: 10px 20px; /* Espaciado interno del botón */
  border-radius: 20px; /* Borde redondeado del botón */
  margin: 5px 0; /* Margen vertical entre botones */
  width: 80%; /* Ancho del 80% */
  text-align: center; /* Centrar texto horizontalmente */
  transition: background-color 0.3s ease; /* Transición suave del color de fondo */
}

/* Estilos para el hover de los botones */
.botonMobile:hover {
  background-color: #0056b3; /* Color de fondo al pasar el ratón por encima */
}

.contentMobile{
  height: 700px !important;
}

/* PRIMARYSCHOOL */
/* Estilo para el contenedor principal */
.contenedorPrimarySchool {
  display: flex; /* Usamos flexbox para crear dos columnas */
  width: 1300px; /* Ancho máximo del contenedor */
  margin: 0 auto; /* Centrar el contenedor en la página */
  padding: 20px; /* Espaciado interior para mejor legibilidad */
  height: 900px;
}

/* Estilo para la columna izquierda */
.columnaPrimarySchool.izquierdaPrimarySchool {
  width: 650px; /* Ancho de la columna izquierda */
  padding: 10px; /* Espaciado interior */
  background-color: #f2f2f2; /* Color de fondo */
  height: 800px;
}

/* Estilo para la columna derecha */
.columnaPrimarySchool.derechaPrimarySchool {
  width: 650px; /* Ancho de la columna derecha */
  padding: 10px; /* Espaciado interior */
  background-color: #e0e0e0; /* Color de fondo */
  height: 800px;
}

.colPrimary{
  width: 350px;
}

.primaryImagen{
  
}

.imagenConvenio{
  height: 613px;
  width: 100%;
}

.estudiantesPortada{
  height: 280px !important;
  width: 280px !important;
}

/* HEADER */
.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: #fff;
}

.logo img {
  width: auto;
  height: 80px; /* Ajusta la altura del logo según sea necesario */
  margin-left: 20px;
}

.social-icons {
  margin-left: auto;
  margin-right: 20px;
}

.webColoegio img{
  width: 180px;
}

.social-icons a {
  display: inline-block;
  margin-left: 10px;
}

.social-icons .imgWeb {
  width: 180px; /* Ajusta el tamaño de los iconos según sea necesario */
  height: 80px;
}

.social-icons a:first-child {
  margin-left: 0;
}


 /* MENU DE COLORES */
 .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 6px;
  position: relative;
}

.item a{
  color: white;
}

.plan{
  width: 126px;
}

.item img {
  max-height: 100%;
  margin-right: 10px; /* Incrementado el margen para separar más la imagen */
}

.item-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  color: white;
  width: 100%;
}

.item p {
  text-align: center;
  margin-bottom: 0;
  color: white;
}

.nombrecolor{
  text-align: center;
  margin-bottom: 0;
  color: #4a75ff !important;
}


.item button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  width: 110%;
  text-align: center;
  border-radius: 25px;
  margin-top: 5px; /* Espacio entre el texto y el botón */
}

.botoncolor{
  background-color: transparent;
  border: 1px solid #4a75ff !important;
  color: #4a75ff !important;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 25px;
  margin-top: 5px; /* Espacio entre el texto y el botón */
}

.item:hover button {
  background-color: #fff;
  opacity: .3;
  color: #4a75ff; /* Cambia al color del fondo del item correspondiente */
}

/* Aplica colores de fondo a los items */
.item:nth-child(1) {
  background-color: #4a75ff;
}

.item:nth-child(2) {
  background-color: #27c367;
}

.item:nth-child(3) {
  background-color: #fec305;
}

.item:nth-child(4) {
  background-color: #ffe753;
  text-align: center;
  margin-bottom: 0;
  color: #054cc0;
}

.item p:nth-child(4) {
  background-color: #ffe753;
  text-align: center;
  margin-bottom: 0;
  color: #054cc0;
}

.item:nth-child(5) {
  background-color: #ffffff;
}

.item:nth-child(6) {
  background-color: #054cc0;
}

.item:nth-child(7) {
  background-color: #8817bd;
}

.imagenColores{
  width: 70px !important;
  height: 70px !important;
}

/* CARDS DEL CARRUSEL */
.main-container {
  /* width: 100%; */
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.content {
  display: flex;
  align-items: center;
  width: 600px;
  height: 400px;
  background-color: #4a75ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.content1 {
  background-color: #92acff!important;

}

.image-container {
  flex: 1;
}

.image-container img {
  max-width: 800px;
  min-height: 30px;
}

.text-container {
  flex: 1;
  padding: 20px;
}

.text-container h2 {
  color: #fff;
}

.text-container p {
  color: #fff;
  margin: 10px 0;
  text-align: justify;
}

.text-container button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.text-container button:hover {
  background-color: darkblue;
}

/* BARRA INFORMATIVA INFERIOR */
.custom-div {
  width: 100%;
  height: 150px;
  background-color: #4a75ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-align: center;
}

.custom-div .columnInfo h2{
  color: white;
}

.columnInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columnInfo img{
  width: 200px;
}

.social-icons img{
  width: 200px;
}

/* Estilos para los iconos de redes sociales */
.social-icons i {
  font-size: 24px;
  color: white;
}


/* FOOTER */
.footer{
  margin-bottom: 3px;
}

.footer2{
  width: 100%;
  background-color: #E4E4E4;
  height: auto;
  align-content: center;
  align-items: center;
}

.textofooterresponsive{
  padding: 15px;
}

.whatsappReponsive{
  width: 75px;
  height: 75px;
}

.custom-footer {
  width: 100%;
  margin: 1% 0;
  background-color: #E4E4E4;
  display: flex;
  justify-content: space-between;
  height: 150px;
}

.footer-column {
  flex: 1;
  text-align: center;
}
.footer-column1 {
  flex: 1;
  text-align: center;
  padding: 0.5% 3% 0 3%;
  border-left: 1px solid ;
}

.footer-column img {
  width: 30px;
  height: 30px;
  position: relative;
  right: 10%;
}


.footer-column a {
  margin: 2px;
  position: relative;
  top: 30%;
  
}

.whatsapp{
  width: 80px !important;
  height: 80px !important;
}

.logoFooter{
  width: 150px !important;
  height: 150px !important;
}

.footer-column1 p {
  color: #666;
  margin: 2px 0;
  text-align: left;
}

.social{
  width: 50px;
}

.social1{
  width: 80px;
  position: fixed !important;
  left: 92%;
  bottom: 55px;
}

.ciudad{
  color: #312782;
  font-weight: bold;
  text-align: center;
  text-decoration: none !important;
}

.ciudad strong{
  position: relative;
  bottom: 10px;
}


.Separadora{
  width: 100%;
  height: 0.1px;
  background-color: #312782;
}

/* MENU */
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  padding: 0 10px;
}

.menu-column {
  display: flex;
  flex-direction: row; /* Cambio a dirección horizontal */
  align-items: center; /* Centrar verticalmente */
  margin-right: 20px; /* Espacio entre los ítems del menú y los íconos */
}

.menu-column a {
  text-decoration: none;
  color: rgb(37, 37, 37);
  margin: 0 10px; /* Espacio horizontal entre los ítems del menú */
  font-family: "Helvetica", Sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-column a:hover {
  color: #312782;
  border-top: 3px solid #312782;
  line-height: 50px;
}

.social-icons {
  display: flex;
  align-items: center; /* Centrar verticalmente */
}

.social-icons a {
  margin-right: 10px; /* Espacio entre los íconos */
}

.social-icons img {
  width: 25px;
  height: 25px;
}

.footerPrincipal{
  background-color: #4a75ff;
border-top: 3px solid #4a75ff;
border-bottom: 3px solid #4a75ff;

}

/* CONTAINER INFORMATIVO */
.container-informativo {
  display: flex;
  width: 100%;
}

.column {
  flex: 1;
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
}

.column img {
  max-width: 100%;
  height: auto;
}

.imagenNosotros{
  height: 613px;
  width: 100%;
}


/* CSS DE NOSOTROS */
.containerNosotros {
  display: flex;
  justify-content: space-between; /* Espacio igual entre las columnas */
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}

.rowNosotros {
  display: flex;
  justify-content: space-between; /* Distribuye las columnas con espacio entre ellas */
}

.colNosotros {
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
}

.blue-textNosotros {
  color: #4a75ff;
}

.text-justifyNosotros {
  text-align: justify;
}

.centered-image{
  width: 486px;
  height: 305px;
}

/* CSS DE LA VISTA DE PROYECTO EDUCATIVO */
.imagen-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.imagenidioma {
  width: 100%;
  height: auto;
  display: block;
}

.videoeducativo {
  position: absolute;
  bottom: 150px;
  left: 10px;
}





/* PANTALLA DE VIDA EN EL COLEGIO */
.titulosVida{
  text-align: center;
}

.infoVidaColegio{
  width: 80%;
  margin:0 auto;
  text-align: justify;
  padding: 10px;
}

.containerVida {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
}

.image-containerVida {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.image-containerVida img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-containerVida {
  flex: 1;
}

.titleVida {
  color: #312783;
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  margin-bottom: 5px;
}

.descriptionVida {
  font-size: 13px;
}

/* ADMISIONES Y MATRICULAS */
.containerMatriculas {
  display: flex;
  justify-content: space-between;
}

.columnMatriculas {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.columnMatriculas1 {
  padding: 20px;
  box-sizing: border-box;
  width: 452px;
}

.imgMatriculas{
  width: 100%;
  height: 100%;
}

.columnMatriculas1.blueMatriculas {
  background-color: #352A87;
  color: white;
}

.centeredMatriculas {
  text-align: center;
  color: #352A87;
}

.form-containerMatriculas {
  display: flex;
  flex-direction: column;
}

.form-containerMatriculasResponsive {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.form-containerMatriculasResponsive input,
.form-containerMatriculasResponsive select,
.form-containerMatriculasResponsive button {
  margin: 5px 0;
  padding: 5px;
}

.form-containerMatriculas input,
.form-containerMatriculas select,
.form-containerMatriculas button {
  margin: 5px 0;
  padding: 5px;
}

.containerBlog {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.itemBlog {
  border: 1px solid #ccc;
  padding: 10px;
}

/* VISITANOS */
.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Centra verticalmente en la pantalla */
}

.containerVisitanos {
  width: 80%; /* Ancho del 80% */
  max-width: 560px; /* Ancho máximo de 560px */
  height: 300px; /* Alto de 300px */
}

.galeriaVisitanos{
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* CONVENIOS */
.imagenConvenio{
  width: 100%;
  height: auto;
}

.imagenConvenioResponsive{
  width: 100% !important;
  height: auto;
}

.tituloConvenios{
  text-align: center;
}

.textoCovenios{
  width: 80%;
  margin: 0 auto;
}

/* AdmisionesMatriculas */
/* Estilo para el contenedor principal */
.contenedorAdmisionesMatriculas {
  display: flex; /* Usamos flexbox para crear dos columnas */
  justify-content: space-between; /* Espacio entre las columnas */
  max-width: 800px; /* Ancho máximo del contenedor */
  margin: 0 auto; /* Centrar el contenedor en la página */
  padding: 20px; /* Espaciado interior para mejor legibilidad */
}

/* Estilo para la columna izquierda */
.columnaAdmisionesMatriculas.izquierdaAdmisionesMatriculas {
  flex-basis: 48%; /* Ancho de la columna izquierda */
  padding: 10px; /* Espaciado interior */
  background-color: #f2f2f2; /* Color de fondo */
  color: #fff;
}

/* Estilo para la columna derecha */
.columnaAdmisionesMatriculas.derechaAdmisionesMatriculas {
  flex-basis: 48%; /* Ancho de la columna derecha */
  padding: 10px; /* Espaciado interior */
  background-color: #312782; /* Color de fondo */
  color: #fff;
}


@media (max-width: 768px) {
    /* HEADER */
    .header {
      flex-direction: column;
      justify-content: center;
      height: auto;
      padding: 10px;
    }
  
    .logo img {
      margin: 0 auto 10px;
      width: 150px;
      height: auto;
    }
  
    .social-icons {
      margin: 0 auto;
      order: 2;
    }
  
    .social-links,
    .contact-info {
      text-align: center; /* Centra el contenido en dispositivos móviles */
      width: 100%; /* Asegura que ocupen todo el ancho */
    }
  
    .social-links a,
    .contact-info a {
      display: block;
      margin: 5px auto;
    }

    /* MENU DE COLORES */
    .container {
      flex-direction: column; /* Cambia la dirección a vertical */
      align-items: center; /* Centra horizontalmente los elementos */
    }
  
    .item {
      width: 100%; /* Ocupa todo el ancho en dispositivos móviles */
      height: auto; /* Ajusta la altura automáticamente */
      border-bottom: 1px solid white; /* Agrega un borde entre los ítems */
    }
  
    .item:last-child {
      border-bottom: none; /* Elimina el borde en el último ítem */
    }
  
    .item img {
      margin-right: 0; /* Remueve el margen derecho en dispositivos móviles */
    }
  
    .item-content {
      align-items: flex-start; /* Alinea el contenido al inicio verticalmente */
      padding: 10px; /* Agrega espacio al contenido */
    }
  
    .item p {
      text-align: left; /* Alinea el texto a la izquierda */
    }
  
    .item button {
      width: auto; /* Ajusta el ancho automáticamente */
      margin-top: 10px; /* Incrementa el espacio entre el texto y el botón */
    }

    .menu {
      flex-direction: column; /* Cambia la dirección a vertical */
      align-items: center; /* Centra horizontalmente los elementos */
    }
  
    .menu-column {
      flex-direction: column; /* Cambia la dirección a vertical */
      align-items: center; /* Centra horizontalmente los ítems del menú */
      margin: 10px 0; /* Espacio entre las columnas */
    }
  
    .menu-column a {
      margin: 5px 0; /* Espacio vertical entre los ítems del menú */
    }
  
    .social-icons {
      margin-top: 20px; /* Espacio entre las columnas del menú y los íconos */
    }
  
    .imgWeb {
      order: 2; /* Cambia el orden para que imgWeb aparezca debajo */
    }
  
    .social-icons img {
      width: 25px;
      height: 25px;
    }

    /* CARDS DEL CARRUSEL */
    .main-container {
      flex-direction: column; /* Cambia la dirección a vertical */
      align-items: center; /* Centra horizontalmente los elementos */
    }
  
    .content {
      flex-direction: column; /* Cambia la dirección a vertical */
      max-width: 100%; /* Ocupa todo el ancho */
      margin-bottom: 20px; /* Espacio entre los "cards" */
      height: auto;
    }
  
    .image-container {
      order: 2; /* Cambia el orden para que la imagen aparezca arriba */
    }
  
    .image-container img {
      max-width: 100%;
      height: auto;
    }
  
    .text-container {
      order: 1; /* Cambia el orden para que el texto aparezca abajo */
      padding: 20px;
    }

    .imagenPortada{
      height: 150px;
      width: 100%;

    }

    .custom-div{
      display: block;
      height: 100%;
    }

    /* FOOTER */
    .custom-footer {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column,
    .footer-column1 {
      width: 100%;
      padding: 0;
      text-align: center;
      border: none;
      background-color: #E4E4E4;
    }
  
    .whatsapp {
      width: 80px !important;
      height: 80px !important;
      order: 4; /* Cambia el orden para que aparezca al final */
      right: -50px !important;
    }
    .logoFooter{
      right: inherit !important;
    }

    .imagenNosotros {
      height: auto;
      width: 100%;
  }

  .containerNosotros{
    visibility: collapse;
    width: 0;
    height: 0;
  }

  /* Estilos para .rowNosotros para mostrar el contenido en filas */
  .rowNosotros {
    display: flex;
    flex-direction: column; /* Cambia la dirección de flexbox a column para mostrar los elementos en filas */
  }

  /* Estilos para .text-justifyNosotros para ajustarse al ancho del contenedor */
  .text-justifyNosotros {
    width: 90%; /* Ajusta el ancho al 100% del contenedor */
    margin-left: 0; /* Elimina el margen izquierdo para ocupar todo el ancho */
    padding: 5px;
  }

  .text-justifyNosotrosResponsive{
    padding: 10px;
  }

  .nosotrosResponsive{
    width: 100% !important;
  }
}